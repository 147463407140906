import React from 'react'
import PropTypes from 'prop-types'

import Filter from 'components/shared/filter/filter'

export default function SuburbsFilter(props) {
  if (props.country !== 'au') return null

  return (
    <Filter
      name='suburb'
      defaultText='All suburbs'
      onChange={props.onChange}
      data={props.suburbs}
    />
  )
}

SuburbsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  suburbs: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
}
