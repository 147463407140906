import superagent from 'superagent'
import { helpers } from 'helpers/helpers'

export const auditLogsApi = {
  downloadFile,
  fetchFileContent,
}

function downloadFile(response, filename) {
  helpers.downloadFile(response.text, filename)
}

function fetchFileContent(filename) {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/audit_logs/download_file`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const params = { filename }

  return superagent
    .get(url)
    .query(params)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
