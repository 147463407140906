import moment from 'moment'
import { isEmpty } from 'lodash'

export const helpers = {
  downloadFile,
  orderGenerationDate,
  shipmentDate,
  cutoffDate,
  weekdayNumber,
  removeFromHash,
  today,
  showSuburbs,
}

function today(strFormat = 'YYYY-MM-DD') {
  return moment().format(strFormat)
}

function downloadFile(data, filename) {
  const blob = new Blob([data])
  let a = window.document.createElement('a')

  a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
  a.download = filename
  document.body.appendChild(a)

  if (!window.Cypress) {
    a.click()
  }

  document.body.removeChild(a)
}

function orderGenerationDate(country, deliveryDate) {
  const editWindowDays = 5
  const minCutoff = {
    at: 5,
    au: 5,
    be: 5,
    de: 5,
    ms: 4,
    nl: 5,
    us: 4,
  }

  const minCutoffForCountry = minCutoff[country]

  const beginningOfDeliveryWeek = moment(deliveryDate).startOf('isoWeek')

  const generationDate = beginningOfDeliveryWeek
    .subtract(3, 'weeks')
    .subtract(minCutoffForCountry, 'days')
    .subtract(editWindowDays, 'days')

  return generationDate.format('YYYY-MM-DD')
}

function shipmentDate(transitTime, deliveryDate) {
  const shipmentDate = moment(deliveryDate).subtract(transitTime / 24, 'days')

  return shipmentDate.format('YYYY-MM-DD')
}

function cutoffDate(cutoffDays, deliveryDate) {
  const cutoffDate = moment(deliveryDate).subtract(cutoffDays, 'days')

  return cutoffDate.format('YYYY-MM-DD')
}

export function sortByWeekday(data) {
  return data.sort((x, y) => weekdayNumber(x) - weekdayNumber(y))
}

function weekdayNumber(weekday) {
  const wdays = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  }

  return wdays[weekday]
}

function removeFromHash(key, hash) {
  let { [key]: _omit, ...leftovers } = hash

  return leftovers
}

export const displayField = value => {
  // isEmpty returns true for numbers
  if (typeof value === 'number') {
    return value
  } else if (isEmpty(value)) {
    return 'n/a'
  } else if (Array.isArray(value)) {
    return value.join(', ')
  }

  return value
}

export const NUMBER_TO_WEEKDAY = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
}

export const numberToWeekday = num => {
  return NUMBER_TO_WEEKDAY[num]
}

export const formatAllValue = value => {
  return value && value !== 'all' ? value : ''
}

function showSuburbs(country) {
  return 'au' === country
}

export const filterShippingLabel = (data, activeFilters) => {
  return data.filter(item =>
    Object.entries(activeFilters).every(([key, value]) => {
      if (key === 'shipmentNumber') {
        return item[key].toLowerCase().includes(value.toLowerCase())
      }
      return item[key] === value
    }),
  )
}
