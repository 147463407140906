import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AppConfig from 'config'

import './orderPageLink.css'
import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

export function OrderPageLink(props) {
  const [copied, setCopied] = useState(false)

  const orderNumber = () => {
    const orderNumberRegex = new RegExp(`(DN-)?(${countries().join('|')})-R\\d+`, 'i')
    const match = props.shipmentNumber.match(orderNumberRegex)

    return match && match[0]
  }

  const countries = () => AppConfig.countries.map(country => country.name)

  const handleCopy = shipmentNumber => {
    navigator.clipboard.writeText(shipmentNumber).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }
  return (
    <div>
      <a
        href={`${process.env.REACT_APP_MS_ADMIN_URL}/orders/${orderNumber()}/delivery`}
        target='_blank'
        rel='noopener noreferrer'
        className='ship-address-link'
      >
        {props.shipmentNumber}
      </a>
      <Tooltip title='Copy'>
        <IconButton
          id='copy-to-clipboard-icon'
          onClick={() => handleCopy(props.shipmentNumber)}
          sx={{
            padding: '4px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          {copied ? (
            <CheckIcon style={{ fontSize: '20px', color: 'green' }} />
          ) : (
            <ContentCopyIcon style={{ fontSize: '20px' }} />
          )}
        </IconButton>
      </Tooltip>
    </div>
  )
}

OrderPageLink.propTypes = {
  shipmentNumber: PropTypes.string.isRequired,
}

export default OrderPageLink
