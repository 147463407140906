// ----- APPLICATION ACTIONS -----//
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'

export const SET_GLOBAL_FILTER = 'SET_GLOBAL_FILTER'
export const SET_GLOBAL_FILTERS = 'SET_GLOBAL_FILTERS'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

// ---------- AUTHENTICATION ----------//
export const AUTHENTICATION_LOGGED_OUT = '[AUTHENTICATION]_LOGGED_OUT'
export const AUTHENTICATION_LOGIN_REQUESTED = '[AUTHENTICATION]_LOGIN_REQUESTED'
export const AUTHENTICATION_LOGIN_SUCCEEDED = '[AUTHENTICATION]_LOGIN_SUCCEEDED'
export const AUTHENTICATION_TOKEN_INFO_REQUESTED = '[AUTHENTICATION]_TOKEN_INFO_REQUESTED'
export const AUTHENTICATION_TOKEN_REFRESH_REQUESTED = '[AUTHENTICATION]_TOKEN_REFRESH_REQUESTED'

// ----- DELIVERY DATE EXCEPTIONS -----//
export const DELIVERY_DATE_EXCEPTIONS_SELECT_NEW = '[DELIVERY_DATE_EXCEPTIONS]_SELECT_NEW'
export const DELIVERY_DATE_EXCEPTIONS_CLEAR_NEW = '[DELIVERY_DATE_EXCEPTIONS]_CLEAR_NEW'
export const DELIVERY_DATE_EXCEPTIONS_CLEAR_DATA = '[DELIVERY_DATE_EXCEPTIONS]_CLEAR_DATA'

export const DELIVERY_DATE_EXCEPTIONS_CREATE_REQUESTED =
  '[DELIVERY_DATE_EXCEPTIONS]_CREATE_REQUESTED'
export const DELIVERY_DATE_EXCEPTIONS_CREATE_ACCEPTED = '[DELIVERY_DATE_EXCEPTIONS]_CREATE_ACCEPTED'
export const DELIVERY_DATE_EXCEPTIONS_CREATE_FAILED = '[DELIVERY_DATE_EXCEPTIONS]_CREATE_FAILED'

export const DELIVERY_DATE_EXCEPTIONS_DELETE_REQUESTED =
  '[DELIVERY_DATE_EXCEPTIONS]_DELETE_REQUESTED'
export const DELIVERY_DATE_EXCEPTIONS_DELETE_FAILED = '[DELIVERY_DATE_EXCEPTIONS]_DELETE_FAILED'

export const DELIVERY_DATE_EXCEPTIONS_FETCH_REQUESTED = '[DELIVERY_DATE_EXCEPTIONS]_FETCH_REQUESTED'
export const DELIVERY_DATE_EXCEPTIONS_FETCH_SUCCEEDED = '[DELIVERY_DATE_EXCEPTIONS]_FETCH_SUCCEEDED'
export const DELIVERY_DATE_EXCEPTIONS_FETCH_FAILED = '[DELIVERY_DATE_EXCEPTIONS]_FETCH_FAILED'

export const DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_REQUESTED =
  '[DELIVERY_EXCEPTIONS_PERMISSIONS]_FETCH_REQUESTED'
export const DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_SUCCEEDED =
  '[DELIVERY_EXCEPTIONS_PERMISSIONS]_FETCH_SUCCEEDED'
export const DELIVERY_EXCEPTIONS_PERMISSIONS_FETCH_FAILED =
  '[DELIVERY_EXCEPTIONS_PERMISSIONS]_FETCH_FAILED'

// ----- DELIVERY OPTIONS -----//
export const DELIVERY_OPTIONS_FETCH_REQUESTED = '[DELIVERY_OPTIONS]_FETCH_REQUESTED'
export const DELIVERY_OPTIONS_FETCH_SUCCEEDED = '[DELIVERY_OPTIONS]_FETCH_SUCCEEDED'
export const DELIVERY_OPTIONS_FETCH_FAILED = '[DELIVERY_OPTIONS]_FETCH_FAILED'
export const DELIVERY_OPTIONS_UPDATE_FILTERS = '[DELIVERY_OPTIONS]_UPDATE_FILTERS'
export const DELIVERY_OPTIONS_CLEAR_DATA = '[DELIVERY_OPTIONS]_CLEAR_DATA'

// ----- DELIVERY OPTIONS CSV -----//
export const DELIVERY_OPTIONS_CSV_FETCH_REQUESTED = '[DELIVERY_OPTIONS_CSV]_FETCH_REQUESTED'
export const DELIVERY_OPTIONS_CSV_FETCH_SUCCEEDED = '[DELIVERY_OPTIONS_CSV]_FETCH_SUCCEEDED'
export const DELIVERY_OPTIONS_CSV_FETCH_FAILED = '[DELIVERY_OPTIONS_CSV]_FETCH_FAILED'

// ----- DELIVERY PROMISES -----//
export const DELIVERY_PROMISES_FETCH_REQUESTED = '[DELIVERY_PROMISES]_FETCH_REQUESTED'
export const DELIVERY_PROMISES_FETCH_SUCCEEDED = '[DELIVERY_PROMISES]_FETCH_SUCCEEDED'
export const DELIVERY_PROMISES_FETCH_FAILED = '[DELIVERY_PROMISES]_FETCH_FAILED'
export const DELIVERY_PROMISES_CLEAR_DATA = '[DELIVERY_PROMISES]_CLEAR_DATA'
export const DELIVERY_PROMISES_UPDATE_FILTERS = '[DELIVERY_PROMISES]_UPDATE_FILTERS'

// ----- DELIVERY PROMISES CSV -----//
export const DELIVERY_PROMISES_CSV_FETCH_REQUESTED = '[DELIVERY_PROMISES_CSV]_FETCH_REQUESTED'
export const DELIVERY_PROMISES_CSV_FETCH_SUCCEEDED = '[DELIVERY_PROMISES_CSV]_FETCH_SUCCEEDED'
export const DELIVERY_PROMISES_CSV_FETCH_FAILED = '[DELIVERY_PROMISES_CSV]_FETCH_FAILED'

// ----- MAPPINGS ------//
export const MAPPINGS_FETCH_REQUESTED = '[MAPPINGS]_FETCH_REQUESTED'
export const MAPPINGS_FETCH_SUCCEEDED = '[MAPPINGS]_FETCH_SUCCEEDED'
export const MAPPINGS_FETCH_FAILED = '[MAPPINGS]_FETCH_FAILED'

// ----- CREATE ZIPCODES -----//
export const ZIPCODES_CREATE_CSV_UPLOADED = '[ZIPCODES_CREATE]_CSV_UPLOADED'
export const ZIPCODES_CREATE_REQUESTED = '[ZIPCODES_CREATE]_REQUESTED'
export const ZIPCODES_CREATE_SUCCEEDED = '[ZIPCODES_CREATE]_SUCCEEDED'
export const ZIPCODES_CREATE_FAILED = '[ZIPCODES_CREATE]_FAILED'

// ----- UPDATE ZIPCODES -----//
export const ZIPCODES_UPDATE_CSV_UPLOADED = '[ZIPCODES_UPDATE]_CSV_UPLOADED'
export const ZIPCODES_UPDATE_FILTERS = '[ZIPCODES_UPDATE]_FILTERS'
export const ZIPCODES_UPDATE_REQUESTED = '[ZIPCODES_UPDATE]_REQUESTED'
export const ZIPCODES_UPDATE_SUCCEEDED = '[ZIPCODES_UPDATE]_SUCCEEDED'
export const ZIPCODES_UPDATE_FAILED = '[ZIPCODES_UPDATE]_FAILED'

// ----- CREATE DELIVERY OPTIONS -----//
export const DELIVERY_OPTIONS_CREATE_CSV_UPLOADED = '[DELIVERY_OPTIONS_CREATE]_CSV_UPLOADED'
export const DELIVERY_OPTIONS_CREATE_REQUESTED = '[DELIVERY_OPTIONS_CREATE]_REQUESTED'
export const DELIVERY_OPTIONS_CREATE_SUCCEEDED = '[DELIVERY_OPTIONS_CREATE]_SUCCEEDED'
export const DELIVERY_OPTIONS_CREATE_FAILED = '[DELIVERY_OPTIONS_CREATE]_FAILED'

// ----- UPDATE DELIVERY OPTIONS -----//
export const DELIVERY_OPTIONS_UPDATE_CSV_UPLOADED = '[DELIVERY_OPTIONS_UPDATE]_CSV_UPLOADED'
export const DELIVERY_OPTIONS_UPDATE_REQUESTED = '[DELIVERY_OPTIONS_UPDATE]_REQUESTED'
export const DELIVERY_OPTIONS_UPDATE_SUCCEEDED = '[DELIVERY_OPTIONS_UPDATE_SUCCEEDED'
export const DELIVERY_OPTIONS_UPDATE_FAILED = '[DELIVERY_OPTIONS_UPDATE]_FAILED'

// ----- PAUSE DELIVERY OPTIONS -----//
export const DELIVERY_OPTIONS_PAUSE_CSV_UPLOADED = '[DELIVERY_OPTIONS_PAUSE]_CSV_UPLOADED'
export const DELIVERY_OPTIONS_PAUSE_REQUESTED = '[DELIVERY_OPTIONS_PAUSE]_REQUESTED'
export const DELIVERY_OPTIONS_PAUSE_SUCCEEDED = '[DELIVERY_OPTIONS_PAUSE_SUCCEEDED'
export const DELIVERY_OPTIONS_PAUSE_FAILED = '[DELIVERY_OPTIONS_PAUSE]_FAILED'
export const DELIVERY_OPTIONS_PAUSE_ERRORS_CLEAR = '[DELIVERY_OPTIONS_PAUSE]_ERRORS_CLEAR'

// ----- SHIP ADDRESSES -----//
export const SHIP_ADDRESS_FETCH_REQUESTED = '[SHIP_ADDRESS]_FETCH_REQUESTED'
export const SHIP_ADDRESS_FETCH_SUCCEEDED = '[SHIP_ADDRESS]_FETCH_SUCCEEDED'
export const SHIP_ADDRESS_FETCH_FAILED = '[SHIP_ADDRESS]_FETCH_FAILED'
export const SHIP_ADDRESS_UPDATE_REQUESTED = '[SHIP_ADDRESS]_UPDATE_REQUESTED'
export const SHIP_ADDRESS_UPDATE_SUCCEEDED = '[SHIP_ADDRESS]_UPDATE_SUCCEEDED'
export const SHIP_ADDRESS_UPDATE_FAILED = '[SHIP_ADDRESS]_UPDATE_FAILED'

// ----- SHIPMENT OPTIONS -----//
export const SHIPMENT_OPTIONS_FETCH_REQUESTED = '[SHIPMENT_OPTIONS]_FETCH_REQUESTED'
export const SHIPMENT_OPTIONS_FETCH_SUCCEEDED = '[SHIPMENT_OPTIONS]_FETCH_SUCCEEDED'
export const SHIPMENT_OPTIONS_FETCH_FAILED = '[SHIPMENT_OPTIONS]_FETCH_FAILED'
export const SHIPMENT_OPTIONS_UPDATE_FILTERS = '[SHIPMENT_OPTIONS]_UPDATE_FILTERS'
export const SHIPMENT_OPTIONS_CLEAR_DATA = '[SHIPMENT_OPTIONS]_CLEAR_DATA'

// ----- SHIPMENT OPTIONS CSV -----//
export const SHIPMENT_OPTIONS_CSV_FETCH_REQUESTED = '[SHIPMENT_OPTIONS_CSV]_FETCH_REQUESTED'
export const SHIPMENT_OPTIONS_CSV_FETCH_SUCCEEDED = '[SHIPMENT_OPTIONS_CSV]_FETCH_SUCCEEDED'
export const SHIPMENT_OPTIONS_CSV_FETCH_FAILED = '[SHIPMENT_OPTIONS_CSV]_FETCH_FAILED'

// ----- SHIPPING LABELS -----//
export const SHIPPING_LABELS_FETCH_REQUESTED = '[SHIPPING_LABELS]_FETCH_REQUESTED'
export const SHIPPING_LABELS_FETCH_SUCCEEDED = '[SHIPPING_LABELS]_FETCH_SUCCEEDED'
export const SHIPPING_LABELS_FETCH_FAILED = '[SHIPPING_LABELS]_FETCH_FAILED'
export const SHIPPING_LABELS_MISSING_LABELS_FETCH_REQUESTED =
  '[SHIPPING_LABELS]_MISSING_LABELS_FETCH_REQUESTED'
export const SHIPPING_LABELS_MISSING_LABELS_FETCH_SUCCEEDED =
  '[SHIPPING_LABELS]_MISSING_LABELS_FETCH_SUCCEEDED'
export const SHIPPING_LABELS_MISSING_LABELS_FETCH_FAILED =
  '[SHIPPING_LABELS]_MISSING_LABELS_FETCH_FAILED'
export const SHIPPING_LABELS_UPDATE_FILTERS = 'SHIPPING_LABELS_UPDATE_FILTERS'

// ----- AUDIT LOGS -----//
export const AUDIT_LOGS_FETCH_REQUESTED = '[AUDIT_LOGS]_FETCH_REQUESTED'
export const AUDIT_LOGS_FETCH_SUCCEEDED = '[AUDIT_LOGS]_FETCH_SUCCEEDED'
export const AUDIT_LOGS_FETCH_FAILED = '[AUDIT_LOGS]_FETCH_FAILED'
export const AUDIT_LOGS_DOWNLOAD_FILE_REQUESTED = '[AUDIT_LOGS]_DOWNLOAD_FILE_REQUESTED'
export const AUDIT_LOGS_DOWNLOAD_FILE_FAILED = '[AUDIT_LOGS]_DOWNLOAD_FILE_FAILED'
export const AUDIT_LOGS_DOWNLOAD_FILE_SUCEEDED = '[AUDIT_LOGS]_DOWNLOAD_FILE_SUCEEDED'

// ----- SHIPPING CSVS -----//
export const SHIPPING_CSVS_FILTER_DATA_FETCH_REQUESTED =
  '[SHIPPING_CSVS_FILTER_DATA]_FETCH_REQUESTED'
export const SHIPPING_CSVS_FILTER_DATA_FETCH_SUCCEEDED =
  '[SHIPPING_CSVS_FILTER_DATA]_FETCH_SUCCEEDED'
export const SHIPPING_CSVS_FILTER_DATA_FETCH_FAILED = '[SHIPPING_CSVS_FILTER_DATA]_FETCH_FAILED'
export const SHIPPING_CSVS_UPDATE_FILTERS = 'SHIPPING_CSVS_UPDATE_FILTERS'
export const SHIPPING_CSVS_UPDATE_LOADING = 'SHIPPING_CSVS_UPDATE_LOADING'

// ----- TRACKING CSVS -----//
export const TRACKED_SHIPPERS_FETCH_REQUESTED = '[TRACKED_SHIPPERS]_FETCH_REQUESTED'
export const TRACKED_SHIPPERS_FETCH_SUCCEEDED = '[TRACKED_SHIPPERS]_FETCH_SUCCEEDED'
export const TRACKED_SHIPPERS_FETCH_FAILED = '[TRACKED_SHIPPERS]_FETCH_FAILED'
export const TRACKING_ACTIVITIES_CSVS_UPDATE_FILTERS = 'TRACKING_ACTIVITIES_CSVS_UPDATE_FILTERS'
export const TRACKING_ACTIVITIES_CSVS_UPDATE_LOADING = 'TRACKING_ACTIVITIES_CSVS_UPDATE_LOADING'
export const TRACKING_CSVS_UPDATE_FILTERS = 'TRACKING_CSVS_UPDATE_FILTERS'
export const TRACKING_CSVS_UPDATE_LOADING = 'TRACKING_CSVS_UPDATE_LOADING'

// ----- DELIVERY TRACKING -----//
export const DELIVERY_TRACKING_FETCH_REQUESTED = '[DELIVERY_TRACKING]_FETCH_REQUESTED'
export const DELIVERY_TRACKING_FETCH_SUCCEEDED = '[DELIVERY_TRACKING]_FETCH_SUCCEEDED'
export const DELIVERY_TRACKING_FETCH_FAILED = '[DELIVERY_TRACKING]_FETCH_FAILED'

// ----- ROLES -----//
export const ROLES_FETCH_REQUESTED = '[ROLES]_FETCH_REQUESTED'
export const ROLES_FETCH_SUCCEEDED = '[ROLES]_FETCH_SUCCEEDED'
export const ROLES_FETCH_FAILED = '[ROLES]_FETCH_FAILED'

// ----- ORDER MOVEMENT -----//
export const ORDER_MOVEMENT_MOVE_ORDER = '[ORDER_MOVEMENT]_MOVE_ORDER'
export const ORDER_MOVEMENT_UPDATE_CSV_UPLOADED = '[ORDER_MOVEMENT_UPDATE]_CSV_UPLOADED'
export const ORDER_MOVEMENT_UPDATE_REQUESTED = '[ORDER_MOVEMENT_UPDATE]_REQUESTED'
export const ORDER_MOVEMENT_UPDATE_SUCCEEDED = '[ORDER_MOVEMENT_UPDATE]_SUCCEEDED'
export const ORDER_MOVEMENT_UPDATE_FAILED = '[ORDER_MOVEMENT_UPDATE]_FAILED'

// ----- FAILED ORDER MOVEMENT -----//
export const FAILED_ORDER_MOVEMENT_REQUESTED = '[FAILED_ORDER_MOVEMENT]_REQUESTED'
export const FAILED_ORDER_MOVEMENT_SUCCEEDED = '[FAILED_ORDER_MOVEMENT]_SUCCEDED'
export const FAILED_ORDER_MOVEMENT_FAILED = '[FAILED_ORDER_MOVEMENT]_FAILED'

// ----- UPDATE DYNAMIC OPTIONS -----//
export const DYNAMIC_OPTIONS_UPDATE_CSV_UPLOADED = '[DYNAMIC_OPTIONS_UPDATE]_CSV_UPLOADED'
export const DYNAMIC_OPTIONS_UPDATE_REQUESTED = '[DYNAMIC_OPTIONS_UPDATE]_REQUESTED'
export const DYNAMIC_OPTIONS_UPDATE_SUCCEEDED = '[DYNAMIC_OPTIONS_UPDATE]_SUCCEEDED'
export const DYNAMIC_OPTIONS_UPDATE_FAILED = '[DYNAMIC_OPTIONS_UPDATE]_FAILED'
