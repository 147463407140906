import { all } from 'redux-saga/effects'

import { fetchCsvSagas } from './fetchCsv'
import { fetchGraphqlSagas } from './fetchGraphql'
import { authenticationSagas } from './authentication'
import { createExceptionsSagas } from './createExceptions'
import { deleteExceptionsSagas } from './deleteExceptions'
import { createDeliveryOptionsSagas } from './createDeliveryOptions'
import { updateDeliveryOptionsSagas } from './updateDeliveryOptions'
import { updateDynamicOptionsSagas } from './updateDynamicOptions'
import { pauseDeliveryOptionsSagas } from './pauseDeliveryOptions'
import { createZipcodesSagas } from './createZipcodes'
import { updateZipcodesSagas } from './updateZipcodes'
import { fetchShippingCsvFilterDataSagas } from './shippingCsvs/fetchFilterData'
import { fetchMappingsSagas } from './fetchMappings'
import { moveOrdersSagas } from './moveOrders'
import { changeShipAddressSagas } from './changeShipAddress'
import { auditLogsSagas } from './auditLogs'

export default function* rootSaga() {
  yield all([
    ...fetchCsvSagas,
    ...fetchGraphqlSagas,
    ...authenticationSagas,
    ...createExceptionsSagas,
    ...deleteExceptionsSagas,
    ...createDeliveryOptionsSagas,
    ...updateDeliveryOptionsSagas,
    ...updateDynamicOptionsSagas,
    ...pauseDeliveryOptionsSagas,
    ...createZipcodesSagas,
    ...updateZipcodesSagas,
    ...fetchShippingCsvFilterDataSagas,
    ...fetchMappingsSagas,
    ...moveOrdersSagas,
    ...changeShipAddressSagas,
    ...auditLogsSagas,
  ])
}
