import { auditLogsApi } from 'apis/auditLogs.api'
import { call, put, takeLatest } from 'redux-saga/effects'
import authenticationApi from 'apis/authentication.api'
import {
  AUDIT_LOGS_DOWNLOAD_FILE_FAILED,
  AUDIT_LOGS_DOWNLOAD_FILE_REQUESTED,
  AUDIT_LOGS_DOWNLOAD_FILE_SUCEEDED,
} from 'actions/types'

export function* downloadFile(action) {
  try {
    const response = yield call(auditLogsApi.downloadFile, action.filename)
    const parsedResponse = response.body.data.auditLog

    yield put({
      type: AUDIT_LOGS_DOWNLOAD_FILE_SUCEEDED,
      data: { responseBody: parsedResponse },
    })
  } catch (error) {
    const failureAction = {
      type: AUDIT_LOGS_DOWNLOAD_FILE_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}

export const auditLogsSagas = [takeLatest(AUDIT_LOGS_DOWNLOAD_FILE_REQUESTED, downloadFile)]
