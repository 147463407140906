import React from 'react'
import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'
import InfoMessage from 'components/shared/infoMessage/infoMessage'

import './deliveryOptionsTable.css'

const INFO_TEXT =
  "'Price Tier Identifier' is a new field for the Differentiated" +
  ' Shipping Price project. Kindly ignore it.'
class DeliveryOptionsTable extends React.Component {
  fields() {
    let fields = Object.keys(this.props.data[0]).filter(header => header !== 'wday')
    if (this.props.country !== 'au') {
      fields = fields.filter(field => field !== 'suburb')
    }

    return fields
  }

  sortDeliveryOptionsData(data) {
    if (!data || data.length === 0) {
      return []
    }

    const sortFn = option => {
      const region = option.region === null ? '' : option.region
      const wdays = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 7,
      }

      return [
        region,
        wdays[option['deliveryDay']],
        option['shipper'],
        option['slotFrom'],
        option['slotTo'],
      ]
    }

    let sortedData = sortBy(data, sortFn)

    return sortedData
  }

  isEmpty(data) {
    return data && data.length === 0
  }

  render() {
    if (this.props.isLoading) {
      return <div className='delivery-options-table__loader'>Loading...</div>
    } else if (this.props.data) {
      const sortedOptions = this.sortDeliveryOptionsData(this.props.data)

      if (this.isEmpty(sortedOptions)) {
        return (
          <div className='delivery-options-table__no-results-msg'>No delivery options found.</div>
        )
      }

      return (
        <div>
          <InfoMessage message={INFO_TEXT} />

          <ZiplogTable
            tableName='delivery-options-table'
            headers={this.fields()}
            data={sortedOptions}
          />
        </div>
      )
    }

    return false
  }
}

DeliveryOptionsTable.propTypes = {
  country: PropTypes.string,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default DeliveryOptionsTable
